<template>
  <div class="appbutton" ref="appbutton">
    <div class="appbutton__text">
      <slot></slot>
      <div class="appbutton__marker"></div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
export default {
  props: { link: String },
  setup(props) {
    const appbutton = ref(null);

    const randomNumb = ref(null);
    onMounted(() => {
      if (props.link) {
        appbutton.value.addEventListener("click", openNewTab);
      }

      function openNewTab(e) {
        e.stopPropagation();
        e.preventDefault();
        randomNumb.value = "?" + Math.floor(Math.random() * 10000000000000000);
        window.open(props.link + randomNumb.value, "_blank");
      }
    });
    return { appbutton, randomNumb };
  },
};
</script>

<style lang="scss" scoped>
.button-wrapper {
  margin: 0 auto;
  position: relative;
  display: block;
  z-index: 200;
  text-align: inherit;
}
.appbutton {
  position: relative;
  cursor: pointer;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  appearance: none;

  background-color: $main-color-1;
  border: 2px solid $main-color-2;
  border-radius: 4px;
  box-sizing: border-box;
  color: $main-color-4;
  cursor: pointer;
  display: inline-block;
  height: 47px;

  font-style: normal;
  font-stretch: normal;
  font-size: 17px;
  line-height: 29px;
  letter-spacing: 0.5px;

  text-align: center;
  transition: 0.2s color, 0.2s background-color;
  white-space: nowrap;

  min-width: 204px;
  padding: 0 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  :not(.is-mobile) &:hover {
    outline: none;
    & .appbutton__marker {
      transform: scaleX(1);
    }
  }

  &.white {
    background-color: $main-color-2;
  }

  @media screen and (min-width: $large) {
    font-size: 20px;
    font-style: normal;
    font-stretch: normal;
    line-height: 34px;
    letter-spacing: 0.5px;
    padding: 0 20px;
  }
}

.appbutton__text {
  position: relative;
  display: inline-block;
}

.appbutton__marker {
  position: absolute;
  bottom: 5px;
  left: 0px;
  right: 0px;

  height: 1px;
  background-color: $main-color-2;
  transform: scaleX(0);
  transition: 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
  overflow: hidden;
  border-radius: 10px 10px 0 0;

  @media screen and (min-width: $medium) {
    left: 0;
    right: 0;

    height: 1px;
  }

  .white & {
    background-color: $main-color-4;
  }
}

.primary {
  color: #f94680;
  background-color: $main-color-1;
  margin-bottom: 15px;
  &:focus,
  &:hover {
    @media screen and (min-width: $medium) {
      background-color: $main-color-1;
      color: $main-color-1;
    }
  }
}

.secondary {
  color: red;
}
.tertiary {
  color: red;
}
</style>
